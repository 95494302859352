<template>
    <input type="hidden">
</template>

<script>
export default {
    name: 'WHiddenField',
    props: [ 'value' ]
}
</script>

<style lang=scss>

</style>
